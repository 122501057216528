<template>
<div>
    <h2 class="">

        {{ $t('single-testimonial') }} <br>

    </h2>
    <p class="text-muted text-center mt-4">
        <img class="testimonial" src="@/app/assets/img/puzanov.jpg" />  <br>
        <span>{{ $t('a-puzanov') }} </span> <br>{{ $t('a-puzanov-2')  }}
    </p>
</div>
</template>

<script>
export default {
    name: 'FrontendSingleTestimonial',

    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="scss" scoped>
.testimonial {
    border-radius: 50% !important;
    width: 75px;
}
</style>
